import React, { FC, useEffect, useRef, useState } from 'react';
import AContainer from '../../../components/ui/AdminContainer';
import EditorInput from '../../../components/input/EditorInput';
import ReactQuill from 'react-quill';
import styles from './styles.module.scss';
import CheckboxInput from '../../../components/input/CheckboxInput';
import Button from '../../../components/ui/Buttons';
import MultipleInput from '../../../components/input/MultipleInput';
import TextInput from '../../../components/input/TextInput';
import { getSettings, updateSettings } from '../../../utils/provider/admin/settings';
import { ISettings, ISettingsResponse } from '../../../types/entity/Settings';

const Settings: FC = (): JSX.Element => {
  const [settings, setSettings] = useState<ISettings | null>(null);
  const descriptionRef = useRef<ReactQuill>(null);
  const [descriptionError] = useState<string>('');
  const mainHeaderRef = useRef<ReactQuill>(null);
  const [mainHeaderError] = useState<string>('');
  const formTextRef = useRef<ReactQuill>(null);
  const [formTextError] = useState<string>('');
  const isRandomRef = useRef<HTMLInputElement>(null);
  const isEmailRef = useRef<HTMLInputElement>(null);
  const isEmailRequiredRef = useRef<HTMLInputElement>(null);
  const isBrandRef = useRef<HTMLInputElement>(null);
  const isBrandRequiredRef = useRef<HTMLInputElement>(null);
  const isTownRef = useRef<HTMLInputElement>(null);
  const isTownRequiredRef = useRef<HTMLInputElement>(null);
  const isSalonRef = useRef<HTMLInputElement>(null);
  const isSalonRequiredRef = useRef<HTMLInputElement>(null);
  const brandsRef = useRef<HTMLTextAreaElement>(null);
  const [brandsError] = useState<string>('');
  const salonsRef = useRef<HTMLTextAreaElement>(null);
  const [salonsError] = useState<string>('');
  const textButtonRef = useRef<HTMLInputElement>(null);
  const [textButtonError] = useState<string>('');
  const isDayCleanRef = useRef<HTMLInputElement>(null);
  const bitrixResponsibleIdRef = useRef<HTMLInputElement>(null);
  const [bitrixResponsibleIdError] = useState<string>('');

  const onSubmitHandler = async (): Promise<void> => {
    if (settings) {
      const header = descriptionRef.current ? descriptionRef.current.value : '';
      const mainHeader = mainHeaderRef.current ? mainHeaderRef.current.value : '';
      const formText = formTextRef.current ? formTextRef.current.value : '';
      const isRandom = isRandomRef.current ? isRandomRef.current.value : false;
      const isEmail = isEmailRef.current ? isEmailRef.current.checked : false;
      const isEmailRequired = isEmailRequiredRef.current ? isEmailRequiredRef.current.checked : false;
      const isBrand = isBrandRef.current ? isBrandRef.current.checked : false;
      const isBrandRequired = isBrandRequiredRef.current ? isBrandRequiredRef.current.checked : false;
      const isTown = isTownRef.current ? isTownRef.current.checked : false;
      const isTownRequired = isTownRequiredRef.current ? isTownRequiredRef.current.checked : false;
      const isSalon = isSalonRef.current ? isSalonRef.current.checked : false;
      const isSalonRequired = isSalonRequiredRef.current ? isSalonRequiredRef.current.checked : false;
      const brands = brandsRef.current
        ? brandsRef.current.value.split(',').map(function (item) {
            return item.trim();
          })
        : [];
      const salons = salonsRef.current
        ? salonsRef.current.value.split(',').map(function (item) {
            return item.trim();
          })
        : [];
      const buttonText = textButtonRef.current ? textButtonRef.current.value : '';
      const isDayClean = isDayCleanRef.current ? isDayCleanRef.current.checked : false;
      const bitrixResponsibleId = bitrixResponsibleIdRef.current ? bitrixResponsibleIdRef.current.value : '';

      const updatedSetting = {
        brands,
        buttonText,
        formText,
        header,
        isBrand,
        isBrandRequired,
        isEmail,
        isEmailRequired,
        isRandom,
        isSalon,
        isSalonRequired,
        isTown,
        isTownRequired,
        mainHeader,
        salons,
        isDayClean,
        bitrixResponsibleId,
      };

      const { data } = await updateSettings(settings.id, updatedSetting);

      console.log(data);
    }
  };

  useEffect(() => {
    getSettings<ISettingsResponse>()
      .then((response) => {
        setSettings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AContainer>
      <div className={styles.settings}>
        <EditorInput
          refElement={descriptionRef}
          id='header'
          label='Шапка (логотипы)'
          value={settings && settings.header ? settings.header : ''}
          error={descriptionError}
        />
        <EditorInput
          refElement={mainHeaderRef}
          id='mainHeader'
          label='Заголовок на главной'
          value={settings && settings.mainHeader ? settings.mainHeader : ''}
          error={mainHeaderError}
        />
        <EditorInput
          refElement={formTextRef}
          id='header'
          label='Текст над формой'
          value={settings && settings.formText ? settings.formText : ''}
          error={formTextError}
        />
        <div className={styles.settings__checkboxes}>
          <div className={styles.settings__checkbox_line}>
            <CheckboxInput
              refElement={isRandomRef}
              id='isRandom'
              label='Отображать кнопки рандома'
              value={settings && settings.isRandom ? settings.isRandom : false}
            />
          </div>
          <div className={styles.settings__checkbox_line}>
            <CheckboxInput
              refElement={isEmailRef}
              id='isEmail'
              label='Отображать поле “Почта”'
              value={settings && settings.isEmail ? settings.isEmail : false}
            />
            <CheckboxInput
              refElement={isEmailRequiredRef}
              id='isEmailRequired'
              label='Поле обязательное'
              value={settings && settings.isEmailRequired ? settings.isEmailRequired : false}
            />
          </div>
          <div className={styles.settings__checkbox_line}>
            <CheckboxInput
              refElement={isBrandRef}
              id='isBrand'
              label='Отображать поле “Бренд”'
              value={settings && settings.isBrand ? settings.isBrand : false}
            />
            <CheckboxInput
              refElement={isBrandRequiredRef}
              id='isBrandRequired'
              label='Поле обязательное'
              value={settings && settings.isBrandRequired ? settings.isBrandRequired : false}
            />
          </div>
          <div className={styles.settings__checkbox_line}>
            <CheckboxInput
              refElement={isTownRef}
              id='isTown'
              label='Отображать поле “Город”'
              value={settings && settings.isTown ? settings.isTown : false}
            />
            <CheckboxInput
              refElement={isTownRequiredRef}
              id='isTownRequired'
              label='Поле обязательное'
              value={settings && settings.isTownRequired ? settings.isTownRequired : false}
            />
          </div>
          <div className={styles.settings__checkbox_line}>
            <CheckboxInput
              refElement={isSalonRef}
              id='isSalon'
              label='Отображать поле “Салон”'
              value={settings && settings.isSalon ? settings.isSalon : false}
            />
            <CheckboxInput
              refElement={isSalonRequiredRef}
              id='isSalonRequired'
              label='Поле обязательное'
              value={settings && settings.isSalonRequired ? settings.isSalonRequired : false}
            />
          </div>
        </div>
        <div className={styles.form__footer}>
          <div className={styles.form__brands}>
            <MultipleInput
              refElement={brandsRef}
              id='brands'
              label='Отображаемые бренды'
              value={settings && settings.brands ? settings.brands : []}
              error={brandsError}
            />
          </div>

          <div className={styles.form__salon}>
            <MultipleInput
              refElement={salonsRef}
              id='salons'
              label='Отображаемые Салоны'
              value={settings && settings.salons ? settings.salons : []}
              error={salonsError}
            />
          </div>
        </div>
        <TextInput
          refElement={textButtonRef}
          id='textButton'
          label='Текст на кнопке:'
          type='text'
          value={settings && settings.buttonText ? settings.buttonText : ''}
          error={textButtonError}
        />
        <TextInput
          refElement={bitrixResponsibleIdRef}
          id='bitrixResponsibleId'
          label='Ответственный за лиды'
          type='text'
          value={settings && settings.bitrixResponsibleId ? `${settings.bitrixResponsibleId}` : ''}
          error={bitrixResponsibleIdError}
        />
        <CheckboxInput
          refElement={isDayCleanRef}
          id='isDayClean'
          label='Не обнулять автоматически'
          value={settings && settings.isDayClean ? settings.isDayClean : false}
        />
        <Button nom={'set'} clickAction={onSubmitHandler}>
          Сохранить
        </Button>
      </div>
    </AContainer>
  );
};

export default Settings;
