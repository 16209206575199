import React, { FC, useEffect, useState } from 'react';
import AContainer from '../../../components/ui/AdminContainer';
import styles from './styles.module.scss';
import Button from '../../../components/ui/Buttons';
import { IParticipant, IParticipantsResponse } from '../../../types/entity/Participant';
import { addWinners, deleteWinners, getParticipants } from '../../../utils/provider/site/participant';
import { ISuccessResponse } from '../../../types/SuccessResponse';
import { useNavigate } from 'react-router-dom';

const AMembers: FC = (): JSX.Element => {
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const onParticipantClick = (participantId: number): void => {
    if (selectedParticipantsIds.includes(participantId))
      setSelectedParticipantsIds(selectedParticipantsIds.filter((id) => id !== participantId));
    else setSelectedParticipantsIds([...selectedParticipantsIds, participantId]);
  };

  const winnerClickHandler = async (): Promise<void> => {
    if (selectedParticipantsIds.length > 0) {
      const { data } = await addWinners<ISuccessResponse>(selectedParticipantsIds);

      if (data.result) navigate('/winners');
    }
  };

  const deleteClickHandler = async (): Promise<void> => {
    if (selectedParticipantsIds.length > 0) {
      const { data: deleteData } = await deleteWinners<ISuccessResponse>(selectedParticipantsIds);

      if (deleteData.result) {
        const { data: participantsData } = await getParticipants<IParticipantsResponse>();
        setParticipants(participantsData);
      }
    }
  };

  useEffect(() => {
    if (participants.length === 0) {
      getParticipants<IParticipantsResponse>()
        .then(({ data }) => setParticipants(data))
        .catch((error) => console.log('@Members', error));
    }
  }, []);

  return (
    <section>
      <AContainer>
        {/* <Button nom="completeAdmin">Завершить регистрацию</Button> */}
        <div className={styles.admin__membersList}>
          <div className={styles.form__top}>
            <div className={styles.shell}>{`Выбрано ${selectedParticipantsIds.length}`}</div>
            <div>
              <Button nom='winnerAdmin' clickAction={winnerClickHandler}>
                Победитель
              </Button>
              <Button nom='deleteAdmin' clickAction={deleteClickHandler}>
                Удалить
              </Button>
            </div>
          </div>
          <div className={styles.form__bottom}>
            {participants.map((participant, index) => (
              <label className={styles.shell} key={index}>
                <input type='checkbox' className={styles.real} onChange={() => onParticipantClick(participant.id)} />
                <span className={styles.castom} />
                {`${participant.id}. ${participant.name}`}
              </label>
            ))}
          </div>
        </div>
      </AContainer>
    </section>
  );
};

export default AMembers;
