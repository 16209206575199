import React, { FC, PropsWithChildren } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Error from '../../pages/Error';
import Main from '../../pages/Main';
import Login from '../../pages/login';
import MainLayout from '../ui/Layouts/MainLayout';
import Members from '../../pages/Members';
import Choice from '../../pages/Choice';
import WinnersList from '../../pages/Winners';
import ErrorLayout from '../ui/Layouts/ErrorLayout';
import AMembers from '../../pages/Admin/MemberList';
import Settings from '../../pages/Admin/Settings';
import AWinners from '../../pages/Admin/Winners';
import RegistrationPage from '../../pages/Registration';
import PrivateRoutes from '../PrivateRoutes';

const AppRoutes: FC = (): JSX.Element => {
  const GetLayout: FC<PropsWithChildren> = ({ children }) => {
    const { pathname } = useLocation();

    return pathname === '/404' ? <ErrorLayout>{children}</ErrorLayout> : <MainLayout>{children}</MainLayout>;
  };

  return (
    <GetLayout>
      <Routes>
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/' element={<Main />} />
          <Route path='/registration' element={<RegistrationPage />} />
          <Route path='/members' element={<Members />} />
          <Route path='/choice' element={<Choice />} />
          <Route path='/winners' element={<WinnersList />} />
          <Route path='/admin/members' element={<AMembers />} />
          <Route path='/admin/winners' element={<AWinners />} />
          <Route path='/admin/settings' element={<Settings />} />
          <Route path='/404' element={<Error code={404} />} />
          <Route path='*' element={<Navigate to='/404' />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </GetLayout>
  );
};

export default AppRoutes;
