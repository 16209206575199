import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../../../../constants';

export const getParticipants = async <T>(): Promise<T> => {
  if (API_URL !== undefined) {
    const { data } = await axios.get<T>(`${API_URL}/admin/participants`);

    return data;
  } else {
    return {
      data: null,
    } as T;
  }
};

export const createParticipant = async <T>(data: unknown): Promise<AxiosResponse<T>> => {
  if (API_URL !== undefined) {
    return await axios.post<T>(`${API_URL}/admin/participant/create`, data);
  } else {
    return {
      data: false,
    } as AxiosResponse<T>;
  }
};

export const addWinners = async <T>(data: unknown): Promise<AxiosResponse<T>> => {
  if (API_URL !== undefined) {
    return await axios.post<T>(`${API_URL}/admin/participant/winners/add`, data);
  } else {
    return {
      data: false,
    } as AxiosResponse<T>;
  }
};

export const deleteWinners = async <T>(data: unknown): Promise<AxiosResponse<T>> => {
  if (API_URL !== undefined) {
    return await axios.post<T>(`${API_URL}/admin/participant/winners/delete`, data);
  } else {
    return {
      data: false,
    } as AxiosResponse<T>;
  }
};

export const getWinners = async <T>(): Promise<T> => {
  if (API_URL !== undefined) {
    const { data } = await axios.get<T>(`${API_URL}/admin/participants/winners`);

    return data;
  } else {
    return {
      data: null,
    } as T;
  }
};
