import React, { FC, useContext, useEffect, useState } from 'react';
import { IParticipant, IParticipantsResponse } from '../../types/entity/Participant';
import { useNavigate } from 'react-router-dom';
import { addWinners, getParticipants } from '../../utils/provider/site/participant';
import Container from '../../components/ui/Container';
import Reg from '../../components/ui/Registration';
import Title from '../../components/ui/Title';
import styles from './styles.module.scss';
import Button from '../../components/ui/Buttons';
import Ellipse from '../../components/ui/Ellipses';
import { ISuccessResponse } from '../../types/SuccessResponse';
import UserContext from '../../context/user/UserContext';
import { UserContextType } from '../../context/user/IUser';

const ChoicePage: FC = (): JSX.Element => {
  const { getUser } = useContext(UserContext) as UserContextType;
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [number, setNumber] = useState<number | null>(null);
  const [isRandomFinish, setIsRandomFinish] = useState<boolean>(false);
  const [winnersIds, setWinnersIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const user = getUser();

  const onFinishHandler = async (): Promise<void> => {
    if (number) {
      const ids = [...winnersIds, number];

      const { data } = await addWinners<ISuccessResponse>(ids);

      if (data.result) navigate('/winners');
    }
  };

  const onStartRandomHandler = (): void => {
    setIsRandomFinish(false);
    const getRandom = (list: number[]): number => {
      return list[Math.floor(Math.random() * list.length)];
    };

    const arrayOfIds: number[] = [];
    participants.forEach((participant) => {
      if (!winnersIds.includes(participant.id)) arrayOfIds.push(participant.id);
    });

    const interval = setInterval(() => {
      setNumber(getRandom(arrayOfIds));
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      setIsRandomFinish(true);
    }, 3000);
  };

  const nextClickHandler = (): void => {
    if (number) {
      setWinnersIds([...winnersIds, number]);
    }
  };

  const reChoseClickHandler = (): void => {
    onStartRandomHandler();
  };

  const getWinnerName = (): string => {
    const winner = participants.find((participant) => participant.id === number);

    if (winner) return winner.name;
    else return '';
  };

  useEffect(() => {
    if (participants.length === 0) {
      getParticipants<IParticipantsResponse>()
        .then(({ data }) => setParticipants(data))
        .catch((error) => console.log('@Members', error));
    }
  }, []);

  useEffect(() => {
    if (winnersIds.length > 0) onStartRandomHandler();
  }, [winnersIds]);

  return (
    <div>
      <section>
        <Container>
          <Reg>
            <Title>Выбираем победителя №{winnersIds.length + 1}</Title>
            <div className={styles.choice__counter}>
              {number && <span>{number}</span>}
              {isRandomFinish && number && <div className={styles.choice__winner}>{getWinnerName()}</div>}
            </div>
            {user?.role === 'admin' && (
              <div className={styles.choice__buttons}>
                <Button nom='random' clickAction={onStartRandomHandler}>
                  Рандом
                </Button>
                <Button nom='change' clickAction={reChoseClickHandler}>
                  Перевыбрать
                </Button>
                <Button nom='next' clickAction={nextClickHandler}>
                  Следующий
                </Button>
                <Button nom='complete' clickAction={onFinishHandler}>
                  Завершить
                </Button>
              </div>
            )}
          </Reg>
        </Container>
        <Ellipse />
      </section>
    </div>
  );
};

export default ChoicePage;
